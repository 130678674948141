/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
.rs-placeholder {
  display: flex;
}
.rs-placeholder-active {
  animation: placeholder-active 2s ease-in-out infinite;
}
.rs-placeholder-paragraph {
  justify-content: flex-start;
  align-items: flex-start;
}
.rs-placeholder-paragraph-graph {
  flex: 0 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 2px;
  margin-right: 20px;
  background-color: #f2f2f5;
}
.rs-placeholder-paragraph-graph-circle {
  border-radius: 50%;
}
.rs-placeholder-paragraph-rows {
  width: 100%;
}
.rs-placeholder-paragraph-rows > p {
  background-color: #f2f2f5;
}
.rs-placeholder-grid-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.rs-placeholder-grid-col > p {
  background-color: #f2f2f5;
}
.rs-placeholder-grid-col:first-child {
  align-items: flex-start;
}
.rs-placeholder-graph {
  display: inline-block;
  width: 100%;
  background-color: #f2f2f5;
}
.rs-loader {
  height: 18px;
  display: inline-block;
}
.rs-loader::before,
.rs-loader::after {
  content: ' ';
  display: table;
}
.rs-loader::after {
  clear: both;
}
.rs-loader .rs-loader {
  height: 18px;
}
.rs-loader .rs-loader-spin {
  width: 18px;
  height: 18px;
}
.rs-loader .rs-loader-spin::before,
.rs-loader .rs-loader-spin::after {
  width: 18px;
  height: 18px;
}
.rs-loader .rs-loader-content {
  font-size: 12px;
  line-height: 1.5;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader {
  height: 48px;
}
.rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
  line-height: 1.66666667;
}
.rs-loader-spin {
  position: relative;
  display: inline-block;
  float: left;
}
.rs-loader-spin::before,
.rs-loader-spin::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
}
.rs-loader-spin::before {
  border: 3px solid rgba(247, 247, 250, 0.8);
}
.rs-loader-spin::after {
  border-width: 3px;
  border-style: solid;
  border-color: #a6a6a6 transparent transparent;
  animation: loaderSpin 0.6s infinite linear;
}
.rs-loader-content {
  float: left;
  display: inline-block;
  color: #575757;
}
.rs-loader-spin + .rs-loader-content {
  margin-left: 12px;
}
.rs-loader-xs .rs-loader-spin + .rs-loader-content {
  margin-left: 10px;
}
.rs-loader-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}
.rs-loader-vertical {
  width: auto;
  display: inline-block;
}
.rs-loader-vertical .rs-loader {
  flex-direction: column;
}
.rs-loader-vertical .rs-loader-spin {
  margin: 0 auto;
}
.rs-loader-vertical .rs-loader-content {
  line-height: 1.66666667;
  text-align: center;
}
.rs-loader-vertical .rs-loader-spin,
.rs-loader-vertical .rs-loader-content {
  display: block;
  float: none;
}
.rs-loader-vertical .rs-loader-spin + .rs-loader-content {
  margin-left: 0;
  margin-top: 10px;
}
.rs-loader-inverse .rs-loader-content {
  color: #f7f7fa;
}
.rs-loader-inverse .rs-loader-backdrop {
  background: rgba(39, 44, 54, 0.83);
}
.rs-loader-inverse .rs-loader-spin::before {
  border-color: rgba(247, 247, 250, 0.3);
}
.rs-loader-inverse .rs-loader-spin::after {
  border-top-color: #fff;
}
.rs-loader-speed-fast .rs-loader-spin::after {
  animation-duration: 0.4s;
}
.rs-loader-speed-normal .rs-loader-spin::after {
  animation-duration: 0.6s;
}
.rs-loader-speed-slow .rs-loader-spin::after {
  animation-duration: 0.8s;
}
.rs-loader-center,
.rs-loader-backdrop-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
.rs-loader-center .rs-loader,
.rs-loader-backdrop-wrapper .rs-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.rs-loader-center:not(.rs-loader-vertical) .rs-loader,
.rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
  display: flex;
}
.rs-loader-center.rs-loader-vertical .rs-loader,
.rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.rs-loader-center .rs-loader-content,
.rs-loader-backdrop-wrapper .rs-loader-content {
  z-index: 1;
}
.rs-loader-lg .rs-loader {
  height: 64px;
}
.rs-loader-lg .rs-loader-spin {
  width: 64px;
  height: 64px;
}
.rs-loader-lg .rs-loader-spin::before,
.rs-loader-lg .rs-loader-spin::after {
  width: 64px;
  height: 64px;
}
.rs-loader-lg .rs-loader-content {
  font-size: 16px;
  line-height: 4;
}
.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
  height: 94px;
}
.rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
  line-height: 1.25;
}
.rs-loader-md .rs-loader {
  height: 36px;
}
.rs-loader-md .rs-loader-spin {
  width: 36px;
  height: 36px;
}
.rs-loader-md .rs-loader-spin::before,
.rs-loader-md .rs-loader-spin::after {
  width: 36px;
  height: 36px;
}
.rs-loader-md .rs-loader-content {
  font-size: 14px;
  line-height: 2.57142857;
}
.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
  height: 66px;
}
.rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
  line-height: 1.42857143;
}
.rs-loader-sm .rs-loader {
  height: 18px;
}
.rs-loader-sm .rs-loader-spin {
  width: 18px;
  height: 18px;
}
.rs-loader-sm .rs-loader-spin::before,
.rs-loader-sm .rs-loader-spin::after {
  width: 18px;
  height: 18px;
}
.rs-loader-sm .rs-loader-content {
  font-size: 14px;
  line-height: 1.28571429;
}
.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
  height: 48px;
}
.rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
  line-height: 1.42857143;
}
.rs-loader-xs .rs-loader {
  height: 16px;
}
.rs-loader-xs .rs-loader-spin {
  width: 16px;
  height: 16px;
}
.rs-loader-xs .rs-loader-spin::before,
.rs-loader-xs .rs-loader-spin::after {
  width: 16px;
  height: 16px;
}
.rs-loader-xs .rs-loader-content {
  font-size: 12px;
  line-height: 1.33333333;
}
.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
  height: 46px;
}
.rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
  line-height: 1.66666667;
}
.rs-loader-wrapper {
  display: inline-block;
  width: auto;
  line-height: 0;
}
