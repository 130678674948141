@import "./variables.scss";

#trendweight {
	.MuiButton-root {
		text-transform: none;
	}

	.MuiToggleButton-root {
		padding: 0.35em 0.6em;
		@media (min-width: 375px) {
			padding: 0.35em 0.75em;
		}
		height: auto;
		text-transform: none;
		color: $text;
		white-space: nowrap;

		&.Mui-selected {
			color: white;
			background: $blue;

			&:hover {
				background: darken($blue, 10%);
			}
		}
	}

	.MuiTableCell-root:last-child {
		padding-right: 0;
	}

	.MuiTableCell-root:first-child {
		padding-left: 0;
	}
}
