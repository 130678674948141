$family-primary: "Roboto", sans-serif;

$section-padding: 1.5rem 1.5rem;
// $fullhd: 1500px + (2 * $gap);
$tablet: 768px;

$orange: #ff9800;
$yellow: #ffeb3b;
$green: #4caf50;
$turquoise: #009688;
$cyan: #29b6f6;
$blue: #507fc3;
$purple: #9c27b0;
$red: #a50b00;
$primary: $blue !default;

$info: $blue;

$table-border: #eee;

$navbar-breakpoint: $tablet;

// $widescreen-enabled: false;
$fullhd-enabled: false;

@import "~bulma/sass/utilities/_all";
