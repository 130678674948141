@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import "./variables";
@import "~bulma/bulma";
@import "./rsuite";
@import "./mui-overrides";

html {
	margin: 0;
	padding: 0;
	overflow-y: scroll;
}

body {
	padding-right: 0 !important;
}
