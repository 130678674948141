@import "./variables";

.outdated {
	background-color: #fff;
	color: $text;

	.hero-body {
		background-color: $primary;

		.logo {
			max-height: 2rem;
			margin: 0 0;
			@include tablet {
				max-height: 4rem;
				margin: 0 0;
			}
		}
	}
}
.warn {
	background-color: white;
	display: block;
	padding: 2rem 1.5rem;

	h1 {
		// text-transform: uppercase;
		color: $primary;
		line-height: 1;
		font-size: 1.4rem;
		font-weight: 500;
	}

	.emoji {
		padding-left: 0.6rem;
	}

	p {
		padding-top: 1rem;
	}
}

.copywrite {
	font-size: 0.8rem;
	color: lighten($text, 20%);
	padding-bottom: 0.5rem;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@include tablet {
		font-size: 1rem;
	}

	a:hover {
		text-decoration: underline;
	}
}
